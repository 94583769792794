import React, {useCallback, memo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {packagePropType} from '@core/payment/widgets/package/constants/propTypes';

import {Card} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import useThemedPackageHeaderProps from '@phoenix/theming/utils/useThemedPackageHeaderProps';
import useAdultContentData from '@phoenix/user/motivation/utils/useAdultContentData';

import PaymentPackageHeader from './PaymentPackageHeader';
import PaymentPackageBenefitsListWrapper from './PaymentPackageBenefitsListWrapper';
import usePackageSubtitle from '../utils/usePackageSubtitle';
import css from '../styles/PaymentPackageAccordion.css';

const PaymentPackageAccordion = ({
  package: packageData,
  onSelect,
  checked,
  withBenefits = true,
  showBenefitsOnAllPackages = true,
  withCenteredLoPrice = false,
  withDiscountLabel = false,
  withBorderIcon = true,
  squareBenefitsPlusIcon = false,
  onScrollToCard,
  onPageBackground = true,
  showAdditionalBenefitsOnAllPackages = false,
  spacingHorizontalAdditionalBenefit = SpacingSize.SHORT,
}) => {
  const {
    interval,
    title,
    packageName,
    packageBenefits: {
      translatedBenefits,
      translatedAdditionalBenefits,
      benefits,
    },
    isTrialMembership,
    packagePricingDetails: {
      packagePriceData,
      packageDiscountPriceData,
      percentDiscount,
      translateKeyPerPeriod,
    },
  } = packageData;

  const subTitle = usePackageSubtitle({
    interval,
    isTrialMembership,
    title,
    benefitsCount: benefits.length,
  });

  const handleClick = useCallback(
    (event) => {
      onScrollToCard && onScrollToCard();
      // Prevent double click on label
      if (event.target.tagName !== 'INPUT') {
        onSelect?.(packageData);
      }
    },
    [onSelect, packageData, onScrollToCard],
  );

  const {data: headerProps} = useThemedPackageHeaderProps();
  const {isAdultChangeSizeAvailable} = useAdultContentData();

  return (
    <Card
      data-test="paymentPackageAccordion"
      role="button"
      tabIndex={0}
      onClick={handleClick}
      trackingName="paymentPackage"
      onPageBackground={onPageBackground}
    >
      <div
        className={cn(
          css.header,
          checked &&
            withBenefits &&
            Boolean(
              translatedBenefits?.length ||
                translatedAdditionalBenefits?.length,
            ) &&
            css.activeHeader,
        )}
      >
        <PaymentPackageHeader
          title={packageName}
          periodKey={translateKeyPerPeriod}
          currentPrice={packageDiscountPriceData || packagePriceData}
          oldPrice={packageDiscountPriceData ? packagePriceData : null}
          percentDiscount={percentDiscount}
          withDiscountLabel={withDiscountLabel && checked}
          subTitle={subTitle}
          withCenteredLoPrice={withCenteredLoPrice}
          checked={checked}
          spacingSize={SpacingSize.NONE}
          withBigTextSize={isAdultChangeSizeAvailable}
          onPageBackground={false}
          transparent={!checked}
          {...headerProps}
        />
      </div>
      {withBenefits && (
        <PaymentPackageBenefitsListWrapper
          showBenefits={showBenefitsOnAllPackages || checked}
          spacingHorizontalAdditionalBenefit={
            spacingHorizontalAdditionalBenefit
          }
          squareBenefitsPlusIcon={squareBenefitsPlusIcon}
          additionalBenefits={translatedAdditionalBenefits}
          benefits={translatedBenefits}
          showAdditionalBenefits={
            showBenefitsOnAllPackages ||
            showAdditionalBenefitsOnAllPackages ||
            checked
          }
          withBigTextSize={isAdultChangeSizeAvailable}
          withBorderIcon={withBorderIcon}
        />
      )}
    </Card>
  );
};

PaymentPackageAccordion.propTypes /* remove-proptypes */ = {
  package: packagePropType.isRequired,
  onSelect: PropTypes.func,
  checked: PropTypes.bool,
  squareBenefitsPlusIcon: PropTypes.bool,
  withBenefits: PropTypes.bool,
  showBenefitsOnAllPackages: PropTypes.bool,
  withDiscountLabel: PropTypes.bool,
  withCenteredLoPrice: PropTypes.bool,
  withBorderIcon: PropTypes.bool,
  showAdditionalBenefitsOnAllPackages: PropTypes.bool,
  spacingHorizontalAdditionalBenefit: PropTypes.oneOf(
    Object.values(SpacingSize),
  ),
  gender: PropTypes.shape({
    isMale: PropTypes.bool,
    isFemale: PropTypes.bool,
  }),
  orientation: PropTypes.shape({
    isGay: PropTypes.bool,
    isLesbian: PropTypes.bool,
  }),
  onScrollToCard: PropTypes.func,
  onPageBackground: PropTypes.bool,
};

export default memo(PaymentPackageAccordion);
